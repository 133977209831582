<template>
  <div v-loading="dataLoading" class="home-page">
    <div class="app-container">
      <!-- Layout -->
      <el-row :gutter="20" class="row-bg" justify="center">
        <!-- Content -->
        <el-col :sm="24" :md="20">
          <el-form ref="newsForm" :model="newsForm" :rules="newsRules">
            <!-- Row 1 -->
            <el-row :gutter="20">
              <el-col :md="16">
                <div class="brand-color mb-5">タイトル</div>
                <el-form-item prop="title">
                  <el-input
                    v-model="newsForm.title"
                    size="small"
                    tabindex="1"
                  />
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <div class="brand-color mb-5">プッシュ通知</div>
                <el-row :gutter="10">
                  <el-col :md="12">
                    <el-button
                      size="small"
                      class="w-100 btn-custom"
                      :class="{'pressed': newsForm.push_status}"
                      @click="togglePushStatus(1)"
                    >ON</el-button>
                  </el-col>
                  <el-col :md="12">
                    <el-button
                      size="small"
                      class="w-100 btn-custom"
                      :class="{'pressed': !newsForm.push_status}"
                      @click="togglePushStatus(0)"
                    >OFF</el-button>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <!-- Row 2 -->

            <div class="brand-color mb-5">写真</div>
            <el-form-item>
              <el-row type="flex">
                <el-col :span="5">
                  <label id="form-upload" class="form-upload" :class="{preview: urlPreview}">
                    <div v-if="urlPreview">
                      <img :src="urlPreview" @click="imagePreview = true">
                      <div class="middle">
                        <i class="el-icon-zoom-in" style="margin-right:5px" @click="imagePreview = true" />
                      </div>
                    </div>
                    <div v-else>
                      <i class="el-icon-plus" for="uploadInput" />
                      <input id="uploadInput" type="file" @change="handleFileUpload">
                    </div>
                  </label>
                </el-col>
                <el-col v-if="urlPreview" :span="3" style="align-self: flex-end">
                  <input ref="file" type="file" style="display: none" @change="handleFileUpload">
                  <el-button size="small" type="primary" @click="$refs.file.click()">イメージを変更</el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-dialog v-model="imagePreview" title="プレビュー">
              <el-image :src="urlPreview" fit="cover" style="width:100%;height:100%" />
            </el-dialog>
            <!-- Row 3 -->

            <div class="brand-color mb-5">本文</div>
            <el-form-item prop="content">
              <el-input
                v-model="newsForm.content"
                size="small"
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 16 }"
                tabindex="2"
              />
            </el-form-item>
            <!-- Row 4 -->

            <el-row :gutter="10" class="mt-50 mb-50 text-center">
              <el-col>
                <el-button
                  v-if="isCreateForm"
                  type="primary"
                  size="small"
                  @click="handleCreate"
                >作成</el-button>
                <el-button
                  v-else
                  type="primary"
                  size="small"
                  @click="handleUpdate"
                >保存</el-button>
                <el-button
                  type="info"
                  size="small"
                  @click="handleCancel"
                >キャンセル</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { fetchNews, createNews, updateNews } from '@/api/news'
import { uploadImage } from '@/api/upload'

export default {
  name: 'NewsDetail',
  components: {},
  props: {
    isCreateForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateImage = (rule, value, callback) => {
      if (this.urlPreview == null) {
        callback(new Error('「写真」を入力してください'))
      } else {
        callback()
      }
    }
    return {
      imagePreview: false,
      dataLoading: false,
      newsId: this.$route.params.id,
      newsForm: {
        title: null,
        content: null,
        image: null,
        push_status: 1
      },
      newsRules: {
        title: [
          { required: true, trigger: 'blur', message: '「タイトル」を入力してください' }
        ],
        content: [
          { required: true, trigger: 'blur', message: '「本文」を入力してください' }
        ],
        image: [
          { trigger: 'blur', validator: validateImage }
        ]
      },
      // imagePreview: false,
      imageFile: null,
      urlPreview: null
    }
  },
  created() {
    if (!this.isCreateForm) {
      this.getData()
    }
  },
  methods: {
    getData() {
      this.dataLoading = true
      fetchNews(this.$route.params.id).then((response) => {
        if (response.data) {
          this.newsForm.title = response.data.title
          this.newsForm.content = response.data.content
          this.newsForm.push_status = response.data.push_status
          this.newsForm.image = response.data.image
          this.urlPreview = this.newsForm.image ? process.env.VUE_APP_BASE_STORAGE + '/' + this.newsForm.image : null
        }

        // Just to simulate the time of the request
        setTimeout(() => {
          this.dataLoading = false
        }, 0.1 * 1000)
      })
    },

    createData() {
      this.dataLoading = true
      createNews(this.newsForm).then((response) => {
        this.$notify({
          title: 'Success',
          message: 'データは正常に作成されました',
          type: 'success',
          duration: 2000
        })

        // Just to simulate the time of the request
        setTimeout(() => {
          this.dataLoading = false
          this.handleCancel()
        }, 0.1 * 1000)
      })
    },

    updateData() {
      this.dataLoading = true
      updateNews(this.newsId, this.newsForm).then((response) => {
        this.$notify({
          title: 'Success',
          message: 'データは正常に更新されました',
          type: 'success',
          duration: 2000
        })

        // Just to simulate the time of the request
        setTimeout(() => {
          this.dataLoading = false
          this.handleCancel()
        }, 0.1 * 1000)
      })
    },

    upload(imageFile, funcHandle) {
      const formData = new FormData()
      formData.append('file', imageFile)
      formData.append('folder_name', 'news')
      formData.append('type', 'image')
      if (!this.isCreateForm) {
        formData.append('old_file', this.newsForm.image)
      }
      uploadImage(formData).then((response) => {
        this.newsForm.image = response.data.url
        funcHandle()
      })
    },

    handleCreate() {
      this.$refs.newsForm.validate((valid) => {
        if (valid) {
          if (this.imageFile) {
            this.upload(this.imageFile, this.createData)
          } else {
            this.createData()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    handleUpdate() {
      this.$refs.newsForm.validate((valid) => {
        if (valid) {
          if (this.imageFile) {
            this.upload(this.imageFile, this.updateData)
          } else {
            this.updateData()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    handleCancel() {
      const matched = this.$route.matched.filter((item) => item.name)
      this.$router.push(matched[0].redirect)
    },

    pathCompile(path) {
      const { params } = this.$route
      var toPath = path
      return toPath(params)
    },

    togglePushStatus(val) {
      this.newsForm.push_status = val
    },

    // handle Image input
    handleFileUpload(e) {
      const file = e.target.files[0]
      this.imageFile = file
      this.urlPreview = URL.createObjectURL(file)
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-custom{
   border-radius: 7px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 1),
    rgba(187, 183, 178, 1)
  );
  color: #495057;
}
.btn-custom.pressed {
  background-image: linear-gradient(
    rgba(184, 227, 207, 1),
    rgb(14, 92, 238)
  );
}
.form-upload {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: 0;
  background-color: #909399;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  line-height: 100px;
  vertical-align: top;
  input {
    display: none !important;
  }
  input {
    display: none !important;
  }
  img {
    position: relative;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
}

</style>

