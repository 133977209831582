<template>
  <news-detail :is-create-form="true" />
</template>

<script>
import NewsDetail from './components/NewsDetail.vue'

export default {
  name: 'CreateNews',
  components: { NewsDetail }
}
</script>

